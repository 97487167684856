const getters = {
    openEnv: state => state.app.openEnv,
    token: state => state.app.token,
    walletAdd: state => state.app.walletAdd,
    indexData: state => state.app.indexData,
    noticeList: state => state.app.noticeList,
    accountData: state => state.app.accountData,
    shareData: state => state.app.shareData,
    userInfo: state => state.app.userInfo,
    tokenContract: state => state.app.tokenContract,
}
export default getters
