import request from '@/utils/request'

export function login(data) {
    return request({
        url: 'login',
        method: 'post',
        data
    })
}

export function getUserInfo() {
    return request({
        url: 'userInfo',
        method: 'post',
    })
}

export function getConfig() {
    return request({
        url: 'config',
        method: 'post',
    })
}

//购买模型包
export function trade(data) {
    return request({
        url: 'trade',
        method: 'post',
        data
    })
}

//提现
export function withdrawal(data) {
    return request({
        url: 'withdrawal',
        method: 'post',
        data
    })
}

//绑定邀请码
export function bindPid(data) {
    return request({
        url: 'bindPid',
        method: 'post',
        data
    })
}

//闪兑
export function swap(data) {
    return request({
        url: 'swap',
        method: 'post',
        data
    })
}

//个人收益
export function personProfit(data) {
    return request({
        url: 'personProfit',
        method: 'post',
        data
    })
}

//团队收益
export function teamPerformance(data) {
    return request({
        url: 'teamPerformance',
        method: 'post',
        data
    })
}

//充值提现
export function getExchange(data) {
    return request({
        url: 'exchange',
        method: 'get',
        params: data
    })
}

//闪兑记录
export function getSwapRecord(data) {
    return request({
        url: 'swapRecord',
        method: 'get',
        params: data
    })
}

//购买模型记录
export function getPowerList(data) {
    return request({
        url: 'power',
        method: 'get',
        params: data
    })
}

//合约地址
export function getConfigParams(data) {
    return request({
        url: 'configParams',
        method: 'get',
        params: data
    })
}

export function getQuestionBank(data) {
    return request({
        url: 'questionBank',
        method: 'get',
        params: data
    })
}

export function getPerformance(data) {
    return request({
        url: 'performance',
        method: 'post',
        data
    })
}


export function postQuestionBank(data) {
    return request({
        url: 'questionBank',
        method: 'post',
        data
    })
}

export function getknowledge(data) {
    return request({
        url: 'knowledge',
        method: 'get',
        params: data
    })
}

export function getBalance(data) {
    return request({
        url: 'balance',
        method: 'get',
        params: data
    })
}

export function getProfitRecord(data) {
    return request({
        url: 'profitRecord',
        method: 'get',
        params: data
    })
}

export function getRuin(data) {
    return request({
        url: 'ruin',
        method: 'get',
        params: data
    })
}

export function postRuin(data) {
    return request({
        url: 'ruin',
        method: 'post',
        data
    })
}

export function getRuinOrderRecord(data) {
    return request({
        url: 'ruinOrderRecord',
        method: 'get',
        params: data
    })
}
export function getRuinProfitRecord(data) {
    return request({
        url: 'ruinProfitRecord',
        method: 'get',
        params: data
    })
}

//gpt问答接口
export function postAskGpt(data) {
    return request({
        url: 'askGpt',
        method: 'post',
        data
    })
}

//gpt问答接口 消息流
export function askNewGpt(data) {
    return request({
        url: 'askNewGpt',
        method: 'post',
        data
    })
}
export function getUserGptQuestionByUid(data) {
    return request({
        url: 'getUserGptQuestionByUid',
        method: 'post',
        data
    })
}

export function getAskNumber(data) {
    return request({
        url: 'askNumber',
        method: 'post',
        data
    })
}


//收益记录
// event_name=6 静态收益
// event_name=8 帮扶收益
// event_name=5 直推收益
// event_name=10 管理收益
// event_name=9 平级收益
export function getRecordList(data) {
    return request({
        url: 'record',
        method: 'get',
        params: data
    })
}


export function getMiningPool() {
    return request({
        url: 'miningPool',
        method: 'get',
    })
}

export function getNotice(data) {
    return request({
        url: 'notice',
        method: 'get',
        params: data
    })
}

export function getAccount() {
    return request({
        url: 'account',
        method: 'get',
    })
}

export function getShare() {
    return request({
        url: 'share',
        method: 'get',
    })
}

// 1挖矿奖励  3合约奖励
export function getReward(data) {
    return request({
        url: 'reward',
        method: 'get',
        params: data
    })
}

// 提现参数
export function getWithdrawalConfig() {
    return request({
        url: 'withdrawalConfig',
        method: 'get',
    })
}

//兑换
export function convertUsd(data) {
    return request({
        url: 'convertUsd',
        method: 'post',
        data
    })
}

// //提现
// export function withdrawal(data) {
//     return request({
//         url: 'withdrawal',
//         method: 'post',
//         data
//     })
// }

//交易历史
export function financeRecord(data) {
    return request({
        url: 'financeRecord',
        method: 'get',
        params: data
    })
}

//佣金提现
export function withdrawalCommission(data) {
    return request({
        url: 'withdrawalCommission',
        method: 'post',
        data
    })
}

//团队人数
export function getCommissionRecord() {
    return request({
        url: 'commissionRecord',
        method: 'get',
    })
}

//佣金明细
export function getInviteRecord(data) {
    return request({
        url: 'inviteRecord',
        method: 'get',
        params: data
    })
}

//帮助中心
export function getArticle(data) {
    return request({
        url: 'article',
        method: 'get',
        params: data
    })
}

//语言列表
export function getLanguage(data) {
    return request({
        url: 'language',
        method: 'get',
        params: data
    })
}

//活动列表
export function getActivity(data) {
    return request({
        url: 'pledge',
        method: 'get',
        params: data
    })
}

// export function getActivity(data) {
//     return request({
//         url: 'activity',
//         method: 'get',
//         params: data
//     })
// }

//报名活动
export function postActivity(data) {
    return request({
        url: 'pledge',
        method: 'post',
        data
    })
}


export function belPrice(data) {
    return request({
        url: 'belPrice',
        method: 'get',
        params: data
    })
}
