import {login, getUserInfo} from '@/api/index'

import {getToken, setToken, removeToken} from '@/utils/auth'

const state = {
    openEnv: false, // 打开环境
    walletAdd: '', // 获取钱包地址
    token: getToken(),
    userInfo: null,//用户信息
    indexData: null, //首页数据
    noticeList: [], //公告
    accountData: null,//用户信息
    shareData: null,//分享信息
    tokenContract: '',//钱包链接
}

const mutations = {

    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_WALLETADD: (state, walletAdd) => {
        state.walletAdd = walletAdd
    },

    SET_OPENENV: (state, openEnv) => {
        state.openEnv = openEnv
    },
    SET_INDEXDATA: (state, indexData) => {
        state.indexData = indexData
    },
    SET_NOTICELIST: (state, noticeList) => {
        state.noticeList = noticeList
    },
    SET_ACCOUNTDATA: (state, accountData) => {
        state.accountData = accountData
    },
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = userInfo
    },
    SET_SHAREDATA: (state, shareData) => {
        state.shareData = shareData
    },
    SET_TOKENCONTRACT: (state, tokenContract) => {
        state.tokenContract = tokenContract
    }
}

const actions = {
    login({commit}, userInfo) {
        return new Promise((resolve, reject) => {
            login(userInfo).then(response => {
                const {data} = response
                if (data.code == 200) {
                    setToken(data.data.token)
                    commit('SET_TOKEN', data.data.token)
                }
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    logOut({commit}) {
        removeToken()
        sessionStorage.removeItem('invite_code')
        sessionStorage.removeItem('invite_code')
        commit('SET_WALLETADD', '--')
        window.location.reload()
    },
    getUserInfo({commit}) {
        return new Promise((resolve, reject) => {
            getUserInfo().then(response => {
                const {data} = response
                if (data.code == 200) {
                    commit('SET_USERINFO', data.data)
                } else {
                    removeToken()
                    window.location.reload()
                }
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    setWalletAdd: ({commit}, walletAdd) => {
        commit('SET_WALLETADD', walletAdd)
    },
    setToken: ({commit}, token) => {
        commit('SET_TOKEN', token)
    },
    setOpenenv({commit}, openEnv) {
        commit('SET_OPENENV', openEnv)
    },
    setIndexData({commit}, indexData) {
        commit('SET_INDEXDATA', indexData)
    },
    setNoticeList({commit}, noticeList) {
        commit('SET_NOTICELIST', noticeList)
    },
    setAccountData({commit}, accountData) {
        commit('SET_ACCOUNTDATA', accountData)
    },
    setShareData({commit}, shareData) {
        commit('SET_SHAREDATA', shareData)
    },
    setTokenContract({commit}, tokenContract) {
        commit('SET_TOKENCONTRACT', tokenContract)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
