// ja 日语
module.exports = {
    bring2: {
        "stayTuned": "お楽しみに",
        "noMore": "これ以上ない",
        "loading": "ロード中",
        "gpt": "ベリンGPT",
        "search_time_arr": {
            "bz": "この7日間",
            "by": "この1ヶ月",
            "bj": "この3ヶ月",
            "jyn": "この1年"
        },
        "allType": "すべてのタイプ",
        "nav": {
            "walletAddress": "現在のウォレットのアドレス",
            "home": "ホームページ",
            "coin": "AI計算力",
            "community": "コミュニティ",
            "property": "資産",
            "website": "公式ウェブサイト",
            "destroyMining": "破壊マイニング",
            "power": "私の計算能力",
            "exchange": "フラッシュマイニング",
            "invite": "招待",
            "knowledge": "ナレッジベース",
            "whitePaper": "ホワイトペーパー",
            "signedIn": "サインイン済み",
            "signIn": "サインイン",
            "received": "領収済み",
            "signInText": "署名ごとに1 BSDTが取得され、購買計算力の支払いを控除することができます。100日間継続、つまり100 BSDT未満は無料で提供可能",
            "plan": "貝霊調達プログラム",
            "prompt": "Q&Aを記入してサインイン完了",
            "correctAnswer": "正解",
            "wrongAnswer": "不正解",
            "toLearn": "貝霊のナレッジベースで学ぶ",
            "tomorrow": "また明日",
            "obtain": "選択",
            "select": "せんたく"
        },
        "home": {
            "aiPower": "ベリンAI計算力",
            "tripleExpansion": "トリプル拡張富の饗宴",
            "destroyMining": "破壊マイニング",
            "tripleMining": "トリプル破壊 富の強化",
            "aiDialogue": "AI対話 すべてに答える",
            "digitalHuman": "デジタルピープル",
            "website": "公式ウェブサイト",
            "view_details": "詳細の表示"
        },
        "algorithm": {
            "totalInvestment": "総投資額",
            "totalPower": "総計算能力",
            "totallIncome": "総収入",
            "joinNow": "今すぐ参加",
            "joinNowText": "BRING計算能力モデルへようこそ。参加するには、少なくとも100BSDT/USDTを投資する",
            "join": "参加する",
            "welcomeJoin": "BURINGへようこそ！",
            "enter": "を入力してください",
            "availableBalance": "利用可能残高",
            "getPower": "おめでとうございます！",
            "determine": "OK",
            "balance": "残高不足",
            "pleaseRecharge": "計算能力を購入する前に残高を追加してください。",
            "toRecharge": "補充する",
            "participationRecord": "参加記録",
            "coin": "通貨",
            "type": "種類",
            "purchasingPower": "計算能力を購入する",
            "power": "計算能力",
            "date": "日付"
        },
        "mining": {
            "totalInvestment": "投資総額",
            "initialLimit": "BEL先発枠",
            "totalRevenue": "総収入",
            "welcomeText": "破壊マイニングへようこそ",
            "pleaseNum": "送金額を入力してください",
            "destroyCurrency": "破壊されたコイン",
            "balance": "残高",
            "fillQuantity": "数量を入力してください",
            "participateMining": "マイニング成功破棄",
            "successfully": "掘削の破棄に成功しました",
            "participationRecord": "参加記録",
            "inProgress": "進行中",
            "completed": "完了",
            "type": "種類。",
            "destroyMining": "破壊マイニング",
            "date": "日付",
            "detailedRecords": "詳細記録",
            "getPower": "推定獲得計算力",
            "tips": "ヒント",
            "determine": "を選択して、",
            "insufficient": "残高不足"
        },
        "power": {
            "participationRecord": "参加記録",
            "staticOutput": "静的出力",
            "dynamicAcceleration": "動的加速",
            "detailedRecords": "詳細記録",
            "invitee": "被招待者",
            "stable_coin": "獲得計算能力",
            "created_at": "取得時間",
            "total_amount": "合計計算能力",
            "total_power": "余剰計算能力"
        },
        "team": {
            "community": "コミュニティ",
            "team_level": "私のコミュニティ評価",
            "my_rectangle": "私のパフォーマンス",
            "team_rectangle": "コミュニティパフォーマンス",
            "my_power": "私の総合計算力",
            "community_power": "コミュニティ総合力",
            "direct_push_count": "マイダイレクトプッシュ",
            "performance": "業績別",
            "byTime": "時間別",
            "invite_count": "招待ニン数",
            "total_count": "パフォーマンス合計",
            "created_at": "参加時間"
        },
        "property": {
            "address": "現在のウォレットアドレス",
            "total": "総資産",
            "myTotal": "私の資産",
            "recharge": "コインの補充",
            "propose": "引き出し",
            "transferAccounts": "送金",
            "outDetails": "送金詳細",
            "balance": "利用可能残高",
            "outQuantity": "送金数",
            "walletAdd": "引き出しアドレス",
            "commission": "手数料",
            "actualReceipt": "実際のお支払い",
            "proposeConfirmation": "引き出し確認",
            "coin": "引き出し通貨",
            "confirmProposal": "引き出しを確認",
            "proposeRecords": "引き出し記録",
            "homepage": "トップに戻る",
            "viewRecords": "記録を見る",
            "transferDetails": "送金詳細",
            "transferredQuantity": "送金数",
            "toAddress": "送金アドレス",
            "filling": "料金",
            "chargeRecord": "料金記録"
        },
        "convert": {
            "exchangeCurrency": "両替に出す通貨",
            "balance": "残高",
            "proposedAmount": "ご希望の金額をご記入ください",
            "currencyConversion": "両替に入れる通貨",
            "commission": "取扱手数料",
            "feeRatio": "手数料率",
            "exchange": "為替",
            "exchangeRecords": "為替記録",
            "completed": "完了",
            "date": "時間"
        },
        "invite": {
            "myInviteUrl": "私の招待リンク",
            "myInvite": "私の招待者",
            "generateImages": "画像を作成する",
            "copyLink": "リンクをコピーする"
        },
        "knowledge": {
            "welcomeTo": "ようこそ",
            "text2": "貝霊知識ベース",
            "text3": "ナレッジベースは、BSDTを取得するためのサインアップを完了するのに役立つ！"
        },
        "gptPage": {
            "welcomeTo": "ベリンGPTへようこそ、何か問題があったら私を見つけて話をすることができますよ~",
            "pleaseQuestion": "質問を入力してください"
        }
    },


    bring: {
        index: {
            start: '起動する'
        },
        loadMore: '追加のロード',
        success: '操作が成功しました',
        error: '操作に失敗しました',
        hint: '',
        promptNum: '正しい数量を入力してください',
        insufficientBalance: '欠勤手当や残高不足',
        home: {
            myTotalDeposit: '私の総入金',
            myComputingPowerValue: '私の計算能力値',
            usdtRevenueValue: 'USDT 収益値',
            bsdRevenueValue: 'BSDT 収益値',
            invest: '投資',
            tokenWithdrawal: 'トークンを引き出す',
            available: '受領可能',
            withdrawal: '引き出し',
            resumption: '再投資',

            detail: '詳細',
            quantity: '数量',
            coin: '通貨',
            power: 'けいさんりょく',
            item: '時間',
        },
        nav: {
            coinage: '鋳貨',
            popularization: 'プロモーション',
            exchange: '両替',
            asset: '資産',
            wallet: 'ウォレット',
            logOut: 'ログアウト',
        },
        invite: {
            personalPerformance: '個人の収益',
            teamPerformance: 'チームパフォーマンス',
            myInviterAddress: '私の招待アドレス',
            myInvitationLink: '私の招待リンク',
            myDirectInvitationData: '私の直接招待データ',
            myPower: '私の招待計算能力',
            computingPower: '毎日リリース計算能力',
            releasePower: '私の合計リリース計算能力',
            binding: 'バインディング',
            address: 'アドレス',
            power: '計算能力',
            invitingPower: '招待計算能力',
            myComputingPower: '私の現在の総計算能力',
            teamRevenueL: 'チーム入金',
            teamLevel: 'チームVIPレベル',
            promptCode: '正しい招待コードを入力してください',

            staticProfit: 'せいてきえき',
            helpProfit: '収益を援助する.',
            directPushProfit: '直進収益',
            manageProfit: '収益の管理',
            levelProfit: 'レベル収益',
            teamProfit: 'チーム収益',
            inviterAddress: '招待アドレス',
            item: '時間',
            direct_push_total: '私の直進総入金',
            team_power_total: 'チームどうにか',
            team_total_usdt: 'チーム総入金',
            team_total_bsdt: 'チーム総入金',
            team_level_count: 'チームレベル',
            region: '大区域',
            smallArea: '小区域',
            record: 'レコード破り',
        },
        exchange: {
            notYetOpen: 'まだオープンしていません',
            contractAddress: '契約住所',
            exchange: '両替',
            exchangeRecords: '換算レコード',
            exchangeSuccess: '変換に成功しました',
            desc: 'コメント',
            number: '数量',
            tiem: '時間',
            copy: 'コピー',
        },
        conis: {
            withdraw: '引き出し',
            recharge: 'リチャージ',
            record: 'レコード破り',
            platform_address: '入金先住所',
            type_desc: 'クラス',
            amount: '金額',
            free: '手数料',
            state_desc: 'ステータス',
            // within: '15 min以内に入金',
            within: '',
            clickRecharge: 'ワンタッチチャージ',


            staticOutput: '24時間静的産出の詳細',
            dynamicOutput: '24時間動的産出詳細',
            assistanceReward: '支援奨励金の内訳',
        }
    },

    nav: {
        title: 'Aave V2',
        user_name: 'Aave V2',
        home: 'Home',
        account: 'Account',
        activity: 'Activity',
        history: 'Transaction History',
        referral: 'Referral',
        help: 'Help Center',
        whitePaper: 'White Paper',
        language: 'Select Language',
    },
    index: {
        verifier_map: 'ETH verifier map',
        total_ore: 'Total output (ETH)',
        today_eth: 'Today\'s profit (ETH)',
        effective_node: 'Valid node',
        user_revenue: 'User revenue',
        peoples: 'participants',
        receipt_voucher: 'Receipt voucher',
        join_now: 'Join the node immediately and start mining',
        receive: 'Receive',
        view_details: 'View Details',
        participate_activities: 'Participate in activities',
        earn_eth: 'earn ETH',
        user_output: 'User output',
        address: 'Address',
        number: 'Quantity',
        cooperative_partner: 'Partner',
        confirm_view: 'Confirm View',
        notice: 'Notice',
        close: 'Close',
    },
    account: {
        account_number: 'Account',
        wallet_balance: 'Wallet balance (USDT)',
        exchangeable: 'Exchangeable  (ETH)',
        exchange: 'Exchange',
        withdrawal: 'Withdrawal',
        on_chain_balance: 'On-chain balance (USDT)',
        total_output: 'Total output (ETH)',
        mining_reward: 'Mining reward',
        contract_award: 'Contract award',
        quantity: 'Quantity',
        time: 'Time',
        loading: 'Loading...',
        no_more: 'No more',
        load_more: 'Load More',
        event_rewards: 'Event Rewards',
        view_details: 'View details',
    },
    withdraw: {
        exchange: 'Exchange',
        all: 'All',
        eth_to_usdt: 'Exchange ETH for USDT',
        enter_quantity: 'Please enter the quantity',
        exchange_success: 'Redemption succeeded',
    },
    exchange: {
        withdrawal: 'Withdrawal',
        all: 'All',
        mini_amount: 'The minimum withdrawal amount is',
        payment_date: 'It will be sent to the wallet address within 24 hours',
        withdrawal_but: 'Withdrawal',
        withdrawal_success: 'Withdrawal succeeded',
    },
    activity: {
        title: 'Activity',
        no_data: 'No data temporarily',
        threshold: 'USDT threshold:',
        reward_amount: 'Reward amount:',
        distribution_period: 'Distribution period (days):',
        term_validity: 'Validity:',
        participate_activities: 'Participate in activities',
        absent: 'Absent',
        progress: 'Ongoing',
        cancel: 'Cancel',
        settled: 'Settled',
        standard: 'Standard',
        view_details: 'View Details',
        confirm: 'Unidentified',
        success: 'Successful participation',
        cancel_but: 'Cancel',
        accept_but: 'Accept',
        apply_rewards: 'Apply for rewards',
        apply_info: ' ETH will send it to your account on the third day after the successful pledge',
        received_term_validity: 'Effective time of receipt',
        day: 'Day',
    },
    history: {
        title: 'Transaction history',
        exchange_details: 'Exchange details',
        exchange: 'Exchange',
        eth_num: 'ETH quantity',
        usdt_num: 'USDT quantity',
        free_amount: 'Service charge (USDT)',
        withdrawal_details: 'Withdrawal details',
        success: 'Success',
        under_review: 'Being approved',
        fail: 'Failed',
        amount: 'Quantity：',
        apply_amount: 'Actual receipt:',
        free: 'Service charge: ',
        mining: 'Mining ',
        time: 'Time ',
        mining_amount: 'Quantity ',
        activity_reward: 'Activity reward ',
        pledge_reward: 'Pledge reward ',
        no_data: 'No data temporarily ',
        received: 'Received ',
    },
    referral: {
        invite_friends: 'Invite friends',
        earn_commission: 'Earn commission',
        send_invitation_link: 'Send your invitation link, friends will join the node through your link',
        invitation_code: 'My invitation code',
        share_link: 'My sharing link',
        today_commission: 'Today\'s income (ETH)',
        total_commission: 'Total commission (ETH)',
        commission_withdrawal_limit: 'Transferable out (ETH)',
        peoples_all: 'Number of team members',
        peoples_one: 'Direct initiator',
        commission_transfer: 'Commission transfer',
        transfer_account: 'Transfer commission income into account wallet',
        transfer_all: 'Transfer all',
        transfer: 'Transfer',
        L1: 'Level 1',
        L2: 'Level 2',
        L3: 'Level 3',
        transfer_success: 'Transfer succeeded',
        enter_quantity: 'Please enter the quantity',
        overstep_quantity: 'Exceeding transferable commission',
        mini_quantity: 'Minimum transfer amount al least',
        copy_succee: 'Copy succeeded',
        view_rules: 'View rules',
        team_rules: 'Team commission rules',
        rules1: '1. When you invite more than 2 friends to participate in mining, and the USDT amount of each person participating in mining is between USDT500.00-3000.00, each person invited can get 0.1ETH.',
        rules2: '2. When you invite more than 5 friends to participate in mining, and the USDT amount of each person participating in mining is between USDT3000.00-10000.00, each person invited can get 0.2ETH.',
        rules3: '3. When you invite more than 8 friends to participate in mining, and the USDT amount of each person participating in mining is between USDT10000.00-50000.00, each person invited can get 0.3ETH.',
        rules4: '4. When you invite more than 10 friends to participate in mining, and the USDT amount of each person participating in mining is between USDT50000.00-200000.00, each person invited can get 0.5ETH.',
        rules5: 'You can receive the invitation connection in the recommended connection or the share button in the side menu. Please contact customer service for specific activity rules and rewards!',
    },
    commission: {
        title: 'Commission details',
        all: 'All',
        level1: 'Level 1',
        level2: 'Level 2',
        level3: 'Level 3',
        no_data: 'No data temporarily',
    },
    help: {
        title: 'Help Center',
        no_data: 'No data temporarily ',
    },
    language: {
        title: 'Select language',
        determine: 'OK ',
    },
    remind: {
        tips: 'Tips',
        text1: 'In the wallet, please visit',
        text2: 'You are currently visiting a decentralized website, please open it in the wallet application',
        text3: 'Copy URL',
        text4: 'Copy succeeded',
    },
    details: {
        title: 'Participated in liquidity mining',
        rule: 'Rule',
        reward_history: 'Reward History',
        explain: 'Explain',
        explain_data: 'After obtaining the node voucher, it becomes a liquidity provider, and the USDT stored in the wallet will automatically provide liquidity to obtain ETH rewards, which are generated every 6 hours',
        total_rewards: 'Total rewards (ETH)',
        today_eth: 'Today\'s reward (ETH)',
        received: 'Received',
        rule_data: 'Aave V2 The mine pool will provide computing capacity based on the number of Tether USDTs in the wallet',
    },
    activity_details: {
        title: 'You will receive up to',
        reward: 'Reward',
        end: 'End',
        standard: 'Standard',
        mortgaged: 'Mortgaged',
        pledge_reward: 'Pledge reward',
        not_up: 'Your mortgage amount is not up to standard.',
        up_more: 'Recharge more',
        get_rewards: 'Get rewards.',
        received: 'Received',
        have_hand: 'have in hand - Received',
        not_claimed: 'Not claimed',
        apply_participation: 'Apply for participation',
        end_activity: 'End of activity.',
        day: 'day',
        hour: 'hour',
        min: 'min',
        seconds: 'seconds',
        Collected: 'Collected',
    }

}
