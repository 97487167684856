// en 英文
module.exports = {
    "bring2": {
        "stayTuned": "Stay tuned",
        "noMore": "There's no more",
        "loading": "Loading",
        "gpt": "Beiling GPT",
        "search_time_arr": {
            "bz": "Past 7 days",
            "by": "Past month",
            "bj": "Past 3 months",
            "jyn": "Past year"
        },
        "allType": "All types",
        "nav": {
            "walletAddress": "Current wallet address",
            "home": "Home page",
            "coin": "AI computing power",
            "community": "Community",
            "property": "Assets",
            "website": "Official website",
            "destroyMining": "Destruction mining",
            "power": "My computing",
            "exchange": "Flash",
            "invite": "Invite",
            "knowledge": "Knowledge base",
            "whitePaper": "White paper",
            "signedIn": "Signed in",
            "signIn": "Sign-in",
            "received": "Claimed",
            "signInText": "Each check-in can earn 1BSDT, which can be used to offset the payment of purchasing power. Continuous for 100 days, i.e. 100 BSDT or more can be submitted for free",
            "plan": "The Belling Upbringing Program",
            "prompt": "Complete the Q&A and sign in",
            "correctAnswer": "Answer correctly",
            "wrongAnswer": "Wrong answer",
            "toLearn": "Go to the Belling Knowledge Base",
            "tomorrow": "Look forward to seeing you tomorrow",
            "obtain": "obtained",
            "select": "Select"
        },
        "home": {
            "aiPower": "Beling AI computing power",
            "tripleExpansion": "Triple inflated wealth feasts",
            "destroyMining": "Destruction mining",
            "tripleMining": "Triple destruction wealth appreciation",
            "aiDialogue": "AI dialogue  Answer for everything",
            "digitalHuman": "Digital man",
            "website": "Official website",
            "view_details": "View Details"
        },
        "algorithm": {
            "totalInvestment": "Total investment",
            "totalPower": "Final effort",
            "totallIncome": "Gross income",
            "joinNow": "Immediate participation",
            "joinNowText": "Welcome to join BRING computing Power model. You need to invest at least 100 BSDT/USDT to participate in it. ",
            "join": "Join",
            "welcomeJoin": "Welcome to BURING!",
            "enter": "Please enter what you want to transfer to",
            "availableBalance": "Available balance",
            "getPower": "Congratulations on your computing",
            "determine": "OK",
            "balance": "Insufficient balance",
            "pleaseRecharge": "Please recharge it and then purchase the computing power",
            "toRecharge": "Go to charge",
            "participationRecord": "Participation record",
            "coin": "Currency",
            "type": "Type",
            "purchasingPower": "Purchasing power",
            "power": "Computing power",
            "date": "Date"
        },
        "mining": {
            "totalInvestment": "Total investment",
            "initialLimit": "BEL initial limit",
            "totalRevenue": "Total revenue",
            "welcomeText": "Welcome to destroy mining",
            "pleaseNum": "Please enter the quantity you want to transfer",
            "destroyCurrency": "Destruction of currency",
            "balance": "Balance",
            "fillQuantity": "Fill quantity",
            "participateMining": "Participate in mining",
            "successfully": "Destruction mining successful",
            "participationRecord": "Participation record",
            "inProgress": "Under way",
            "completed": "Completed",
            "type": "Type",
            "destroyMining": "Destruction mining",
            "date": "Date",
            "detailedRecords": "Detailed record",
            "getPower": "Expected computing power",
            "tips": "prompt",
            "determine": "confirm",
            "insufficient": "Insufficient balance"
        },
        "power": {
            "participationRecord": "Participation record",
            "staticOutput": "Static output",
            "dynamicAcceleration": "Dynamic acceleration",
            "detailedRecords": "Detailed record",
            "invitee": "Invitee",
            "stable_coin": "Acquired computing",
            "created_at": "Acquisition time",
            "total_amount": "Total computing",
            "total_power": "Residual"
        },
        "team": {
            "community": "Community",
            "team_level": "My community level",
            "my_rectangle": "My performance",
            "team_rectangle": "Community performance",
            "my_power": "My total computing",
            "community_power": "Total community computing",
            "direct_push_count": "My straight push",
            "performance": "By perf",
            "byTime": "By time",
            "invite_count": "Number of people invited",
            "total_count": "Total performance",
            "created_at": "Time for joining"
        },
        "property": {
            "address": "Current wallet address",
            "total": "Total assets",
            "myTotal": "My assets",
            "recharge": "Coin top-up",
            "propose": "Withdraw",
            "transferAccounts": "Transfer money",
            "outDetails": "Transfer-out detail",
            "balance": "Available balance",
            "outQuantity": "Transfer-out quantity",
            "walletAdd": "Transfer address",
            "commission": "Handling charge",
            "actualReceipt": "Actual amount arrived",
            "proposeConfirmation": "Submit confirmation",
            "coin": "Coin withdrawn",
            "confirmProposal": "Withdrawn confirmed",
            "proposeRecords": "Make a record",
            "homepage": "Back to home page",
            "viewRecords": "View record",
            "transferDetails": "Transfer-in detail",
            "transferredQuantity": "Transfer-in quantity",
            "toAddress": "Transfer-in address",
            "filling": "Charge",
            "chargeRecord": "Charge record"
        },
        "convert": {
            "exchangeCurrency": "Exchange currency",
            "balance": "Balance",
            "proposedAmount": "Amount submitted",
            "currencyConversion": "Deposit currency",
            "commission": "Handling charge",
            "feeRatio": "Commission percentage",
            "exchange": "Exchange",
            "exchangeRecords": "Exchange record",
            "completed": "Completed",
            "date": "Time"
        },
        "invite": {
            "myInviteUrl": "My invitation link",
            "myInvite": "My inviter",
            "generateImages": "Generate picture",
            "copyLink": "Copy link"
        },
        "knowledge": {
            "welcomeTo": "Welcome ",
            "text2": "Belling knowledge base",
            "text3": "The knowledge base helps you complete the sign-in to get BSDT"
        },
        "gptPage":{
            "welcomeTo": "Welcome to ChaoAI. If you have any questions, you can come to me and chat with me~",
            "pleaseQuestion": "Please enter your question"
        }
    },

    bring: {
        index: {
            start: 'strrt'
        },
        loadMore: 'Load more',
        success: 'Operation successful',
        error: 'Operation failed',
        promptNum: 'Please enter the correct quantity',
        hint: '',
        insufficientBalance: 'Absenteeism pay or insufficient balance',
        home: {
            myTotalDeposit: 'My Total Deposit',
            myComputingPowerValue: 'Power',
            usdtRevenueValue: 'USDT Revenue Value',
            bsdRevenueValue: 'BSDT Revenue Value',
            invest: 'Invest',
            tokenWithdrawal: 'Token Withdrawal',
            available: 'Available',
            withdrawal: 'Withdrawal',
            resumption: 'Resumption',

            detail: 'Detail',
            quantity: 'Quantity',
            coin: 'Coin',
            power: 'Power',
            item: 'Tiem',
        },
        nav: {
            coinage: 'Coinage',
            popularization: 'Invite',
            exchange: 'Exchange',
            asset: 'Asset',
            wallet: 'Wallet',
            logOut: 'LogOut',
        },
        invite: {
            personalPerformance: 'Personal Benefits',
            teamPerformance: 'Team Performance',
            myInviterAddress: 'My Inviter Address',
            myInvitationLink: 'My Invitation Link',
            myDirectInvitationData: 'My Direct Invitation Data',
            myPower: 'My Invitation Calculation Power',
            computingPower: 'Daily Release Of Computing Power',
            releasePower: 'My Total Release Power',
            binding: 'Binding',
            address: 'Address',
            power: 'Power',
            invitingPower: 'Inviting  Power',
            myComputingPower: 'My Current Total Computing Power',
            teamRevenueL: 'Total Team Revenue',
            teamLevel: 'Team Vip Level',
            promptCode: 'Please enter the correct invitation code',
            promptNum: 'Please enter the correct quantity',
            staticProfit: 'Static returns',
            helpProfit: 'Assistance income',
            directPushProfit: 'Direct push income',
            manageProfit: 'Management income',
            levelProfit: 'Level return',
            teamProfit: 'Team Benefits',
            inviterAddress: 'Address',
            item: 'Time',
            direct_push_total: 'My direct push total deposit',
            team_power_total: 'Total team computing power',
            team_total_usdt: 'Total team investment',
            team_total_bsdt: 'Total team investment',
            team_level_count: 'Team level',
            region: 'bigArea',
            smallArea: 'smallArea',
            record: 'Record',
        },
        exchange: {
            notYetOpen: ' Not yet open ',
            contractAddress: 'Contract Address',
            exchange: 'Exchange',
            exchangeRecords: 'Redemption records',
            exchangeSuccess: 'Redemption successful',
            desc: 'Type',
            number: 'Number',
            tiem: 'Tiem',
            copy: 'Copy',

        },
        conis: {
            withdraw: 'Withdraw',
            recharge: 'Recharge',
            record: 'Record',
            platform_address: 'Recharge address',
            type_desc: 'Class',
            amount: 'Money',
            free: 'Fees',
            state_desc: 'State',
            within: '',
            // within: 'Received within 15 minutes',
            clickRecharge: 'One click recharge',

            staticOutput: '24-Hour Static Output Details',
            dynamicOutput: '24-Hour Dynamic Output Details',
            assistanceReward: 'Assistance Reward Details',
        }
    },

    nav: {
        title: 'Aave V2',
        user_name: 'Aave V2',
        home: 'Home',
        account: 'Account',
        activity: 'Activity',
        history: 'Transaction History',
        referral: 'Referral',
        help: 'Help Center',
        whitePaper: 'White Paper',
        language: 'Select Language',
    },
    index: {
        verifier_map: 'ETH verifier map',
        total_ore: 'Total output (ETH)',
        today_eth: 'Today\'s profit (ETH)',
        effective_node: 'Valid node',
        user_revenue: 'User revenue',
        peoples: 'participants',
        receipt_voucher: 'Receipt voucher',
        join_now: 'Join the node immediately and start mining',
        receive: 'Receive',
        view_details: 'View Details',
        participate_activities: 'Participate in activities',
        earn_eth: 'earn ETH',
        user_output: 'User output',
        address: 'Address',
        number: 'Quantity',
        cooperative_partner: 'Partner',
        confirm_view: 'Confirm View',
        notice: 'Notice',
        close: 'Close',
    },
    account: {
        account_number: 'Account',
        wallet_balance: 'Wallet balance (USDT)',
        exchangeable: 'Exchangeable  (ETH)',
        exchange: 'Exchange',
        withdrawal: 'Withdrawal',
        on_chain_balance: 'On-chain balance (USDT)',
        total_output: 'Total output (ETH)',
        mining_reward: 'Mining reward',
        contract_award: 'Contract award',
        quantity: 'Quantity',
        time: 'Time',
        loading: 'Loading...',
        no_more: 'No more',
        load_more: 'Load More',
        event_rewards: 'Event Rewards',
        view_details: 'View details',
    },
    withdraw: {
        exchange: 'Exchange',
        all: 'All',
        eth_to_usdt: 'Exchange ETH for USDT',
        enter_quantity: 'Please enter the quantity',
        exchange_success: 'Redemption succeeded',
    },
    exchange: {
        withdrawal: 'Withdrawal',
        all: 'All',
        mini_amount: 'The minimum withdrawal amount is',
        payment_date: 'It will be sent to the wallet address within 24 hours',
        withdrawal_but: 'Withdrawal',
        withdrawal_success: 'Withdrawal succeeded',
    },
    activity: {
        title: 'Activity',
        no_data: 'No data temporarily',
        threshold: 'USDT threshold:',
        reward_amount: 'Reward amount:',
        distribution_period: 'Distribution period (days):',
        term_validity: 'Validity:',
        participate_activities: 'Participate in activities',
        absent: 'Absent',
        progress: 'Ongoing',
        cancel: 'Cancel',
        settled: 'Settled',
        standard: 'Standard',
        view_details: 'View Details',
        confirm: 'Unidentified',
        success: 'Successful participation',
        cancel_but: 'Cancel',
        accept_but: 'Accept',
        apply_rewards: 'Apply for rewards',
        apply_info: ' ETH will send it to your account on the third day after the successful pledge',
        received_term_validity: 'Effective time of receipt',
        day: 'Day',
    },
    history: {
        title: 'Transaction history',
        exchange_details: 'Exchange details',
        exchange: 'Exchange',
        eth_num: 'ETH quantity',
        usdt_num: 'USDT quantity',
        free_amount: 'Service charge (USDT)',
        withdrawal_details: 'Withdrawal details',
        success: 'Success',
        under_review: 'Being approved',
        fail: 'Failed',
        amount: 'Quantity：',
        apply_amount: 'Actual receipt:',
        free: 'Service charge: ',
        mining: 'Mining ',
        time: 'Time ',
        mining_amount: 'Quantity ',
        activity_reward: 'Activity reward ',
        pledge_reward: 'Pledge reward ',
        no_data: 'No data temporarily ',
        received: 'Received ',
    },
    referral: {
        invite_friends: 'Invite friends',
        earn_commission: 'Earn commission',
        send_invitation_link: 'Send your invitation link, friends will join the node through your link',
        invitation_code: 'My invitation code',
        share_link: 'My sharing link',
        today_commission: 'Today\'s income (ETH)',
        total_commission: 'Total commission (ETH)',
        commission_withdrawal_limit: 'Transferable out (ETH)',
        peoples_all: 'Number of team members',
        peoples_one: 'Direct initiator',
        commission_transfer: 'Commission transfer',
        transfer_account: 'Transfer commission income into account wallet',
        transfer_all: 'Transfer all',
        transfer: 'Transfer',
        L1: 'Level 1',
        L2: 'Level 2',
        L3: 'Level 3',
        transfer_success: 'Transfer succeeded',
        enter_quantity: 'Please enter the quantity',
        overstep_quantity: 'Exceeding transferable commission',
        mini_quantity: 'Minimum transfer amount al least',
        copy_succee: 'Copy succeeded',
        view_rules: 'View rules',
        team_rules: 'Team commission rules',
        rules1: '1. When you invite more than 2 friends to participate in mining, and the USDT amount of each person participating in mining is between USDT500.00-3000.00, each person invited can get 0.1ETH.',
        rules2: '2. When you invite more than 5 friends to participate in mining, and the USDT amount of each person participating in mining is between USDT3000.00-10000.00, each person invited can get 0.2ETH.',
        rules3: '3. When you invite more than 8 friends to participate in mining, and the USDT amount of each person participating in mining is between USDT10000.00-50000.00, each person invited can get 0.3ETH.',
        rules4: '4. When you invite more than 10 friends to participate in mining, and the USDT amount of each person participating in mining is between USDT50000.00-200000.00, each person invited can get 0.5ETH.',
        rules5: 'You can receive the invitation connection in the recommended connection or the share button in the side menu. Please contact customer service for specific activity rules and rewards!',
    },
    commission: {
        title: 'Commission details',
        all: 'All',
        level1: 'Level 1',
        level2: 'Level 2',
        level3: 'Level 3',
        no_data: 'No data temporarily',
    },
    help: {
        title: 'Help Center',
        no_data: 'No data temporarily ',
    },
    language: {
        title: 'Select language',
        determine: 'OK ',
    },
    remind: {
        tips: 'Tips',
        text1: 'In the wallet, please visit',
        text2: 'You are currently visiting a decentralized website, please open it in the wallet application',
        text3: 'Copy URL',
        text4: 'Copy succeeded',
    },
    details: {
        title: 'Participated in liquidity mining',
        rule: 'Rule',
        reward_history: 'Reward History',
        explain: 'Explain',
        explain_data: 'After obtaining the node voucher, it becomes a liquidity provider, and the USDT stored in the wallet will automatically provide liquidity to obtain ETH rewards, which are generated every 6 hours',
        total_rewards: 'Total rewards (ETH)',
        today_eth: 'Today\'s reward (ETH)',
        received: 'Received',
        rule_data: 'Aave V2 The mine pool will provide computing capacity based on the number of Tether USDTs in the wallet',
    },
    activity_details: {
        title: 'You will receive up to',
        reward: 'Reward',
        end: 'End',
        standard: 'Standard',
        mortgaged: 'Mortgaged',
        pledge_reward: 'Pledge reward',
        not_up: 'Your mortgage amount is not up to standard.',
        up_more: 'Recharge more',
        get_rewards: 'Get rewards.',
        received: 'Received',
        have_hand: 'have in hand - Received',
        not_claimed: 'Not claimed',
        apply_participation: 'Apply for participation',
        end_activity: 'End of activity.',
        day: 'day',
        hour: 'hour',
        min: 'min',
        seconds: 'seconds',
        Collected: 'Collected',
    }

}
