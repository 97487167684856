// ko 韩语
module.exports = {
    bring2:
        {
            "stayTuned": "기대해주세요.",
            "noMore": "더 이상 없어요.",
            "loading": "로드 중",
            "gpt": "베링 GPT",
            "search_time_arr": {
                "bz": "최근 7일",
                "by": "근 1개월",
                "bj": "최근 3개월",
                "jyn": "근 1년"
            },
            "allType": "모든 유형",
            "nav": {
                "walletAddress": "현재 지갑 주소",
                "home": "메인 화면",
                "coin": "AI 계산력",
                "community": "커뮤니티",
                "property": "재산",
                "website": "공식 사이트",
                "destroyMining": "마이닝 소각",
                "power": "나의 해시 레이트",
                "exchange": "즉각 태환",
                "invite": "요청",
                "knowledge": "지식 베이스",
                "whitePaper": "화이트 페이퍼",
                "signedIn": "출석체크 완료",
                "signIn": "출석체크",
                "received": "이미 수령함",
                "signInText": "매번 출석할 때마다 1BSDT를 받을 수 있으며, 구매 계산력을 공제하는 지불에 사용할 수 있다.100일 지속, 즉 100BSDT만 있으면 무료로 제공",
                "plan": "벨링 육성 계획",
                "prompt": "질의응답을 마치면 바로 출석체크 완료됩니다",
                "correctAnswer": "정답",
                "wrongAnswer": "오답",
                "toLearn": "벨링 지식 베이스에 가서 공부합니다.",
                "tomorrow": "내일 또 뵙기를 기대합니다",
                "obtain": "획득",
                "select": "선택"
            },
            "home": {
                "aiPower": "베링 AI 계산력",
                "tripleExpansion": "3배로 팽창하는 재부의 성연",
                "destroyMining": "마이닝 소각",
                "tripleMining": "삼중소각부가가치",
                "aiDialogue": "AI 대화 모든 것에는 답이 있습니다.",
                "digitalHuman": "디지털 휴먼",
                "website": "공식 사이트",
                "view_details": "자세히 보기",
            },
            "algorithm": {
                "totalInvestment": "총투입",
                "totalPower": "총 해시 레이트",
                "totallIncome": "총소득",
                "joinNow": "즉시참여",
                "joinNowText": "BRING 계산 능력 모델에 오신 것을 환영합니다.최소 100 BSDT/USDT를 투자하셔야 참여가 가능합니다.",
                "join": "가입",
                "welcomeJoin": "BURING에 가입한 것을 환영합니다!",
                "enter": "전입하실 것을 입력해 주십시오",
                "availableBalance": "가용잔액",
                "getPower": "해시 레이트를 얻은 것을 축하합니다.",
                "determine": "확인",
                "balance": "잔여부족",
                "pleaseRecharge": "잔액 충전 후 구매하세요",
                "toRecharge": "충전",
                "participationRecord": "참여기록",
                "coin": "통화",
                "type": "유형",
                "purchasingPower": "해시 레이트 구매",
                "power": "해시 레이트",
                "date": "날짜"
            },
            "mining": {
                "totalInvestment": "총투입",
                "initialLimit": "BEL선발한도",
                "totalRevenue": "총소득",
                "welcomeText": "마이닝 소각에 가입한 것을 환영합니다.",
                "pleaseNum": "입금하실 수량을 입력해 주세요",
                "destroyCurrency": "소각통화",
                "balance": "잔액",
                "fillQuantity": "수량입력",
                "participateMining": "마이닝 참여",
                "successfully": "마이닝 소각 성공",
                "participationRecord": "참여기록",
                "inProgress": "진행중",
                "completed": "완료",
                "type": "유형",
                "destroyMining": "마이닝 소각",
                "date": "날짜",
                "detailedRecords": "상세기록",
                "getPower": "예상 계산력",
                "tips": "힌트",
                "determine": "확인",
                "insufficient": "잔액 부족"
            },
            "power": {
                "participationRecord": "기록 참여",
                "staticOutput": "정적 산출",
                "dynamicAcceleration": "동적 가속",
                "detailedRecords": "상세기록",
                "invitee": "요청 받은 사람",
                "stable_coin": "해시 레이트 획득",
                "created_at": "획득 시간",
                "total_amount": "총 해시 레이트",
                "total_power": "잉여 계산력"
            },
            "team": {
                "community": "커뮤니티",
                "team_level": "내 커뮤니티 수준",
                "my_rectangle": "나의 업적",
                "team_rectangle": "커뮤니티 업적",
                "my_power": "나의 총 해시 레이트",
                "community_power": "커뮤니티 총 해시 레이트",
                "direct_push_count": "나의 직추",
                "performance": "업적별",
                "byTime": "시간별",
                "invite_count": "요청 인수",
                "total_count": "총 업적",
                "created_at": "가입시간"
            },
            "property": {
                "address": "현재 지갑 주소",
                "total": "총 자산",
                "myTotal": "나의 재산",
                "recharge": "충전",
                "propose": "제출",
                "transferAccounts": "이체",
                "outDetails": "전출내역",
                "balance": "가용잔액",
                "outQuantity": "전출수량",
                "walletAdd": "전출주소",
                "commission": "수수료",
                "actualReceipt": "실제입금",
                "proposeConfirmation": "확인제출",
                "coin": "통화제출",
                "confirmProposal": "제출확인",
                "proposeRecords": "기록제출",
                "homepage": "첫 페이지로 돌아가기",
                "viewRecords": "기록 보기",
                "transferDetails": "전입내역",
                "transferredQuantity": "전입수량",
                "toAddress": "전입주소",
                "filling": "충입",
                "chargeRecord": "충입기록"
            },
            "convert": {
                "exchangeCurrency": "환출통화",
                "balance": "잔액",
                "proposedAmount": "제출금액 기입",
                "currencyConversion": "환입통화",
                "commission": "수수료",
                "feeRatio": "수수료비율",
                "exchange": "태환",
                "exchangeRecords": "태환기록",
                "completed": "완료",
                "date": "시간"
            },
            "invite": {
                "myInviteUrl": "나의 요청 링크",
                "myInvite": "나의 초청자",
                "generateImages": "이미지 생성",
                "copyLink": "링크 복사"
            },
            "knowledge": {
                "welcomeTo": "환영합니다",
                "text2": "벨링 지식 베이스",
                "text3": "지시 베이스에서  출석체크하고 BSDT를 획득할 수 있도록 도와줍니다."
            },
            "gptPage":{
                "welcomeTo": "베링 GPT에 오신 것을 환영합니다. 질문이 있으면 언제든지 저를 찾아와 이야기를 나눌 수 있습니다.",
                "pleaseQuestion": "질문을 입력하십시오."
            }
        },
    bring: {
        index: {
            start: '시작'
        },
        loadMore: '추가 로드',
        success: '작업 성공',
        error: '작업 실패',
        hint: '',
        promptNum: '올바른 수량을 입력하십시오.',
        insufficientBalance: '결근수당 또는 잔액 부족',
        home: {
            myTotalDeposit: '나의 총 입금',
            myComputingPowerValue: '나의 산력치',
            usdtRevenueValue: 'USDT 수익',
            bsdRevenueValue: 'BSDT 수익',
            invest: '투입',
            tokenWithdrawal: '화폐 인출',
            available: '수령가능',
            withdrawal: '인출',
            resumption: '중복투자',

            detail: '명세',
            quantity: '수량',
            coin: '화폐 종류',
            power: '계산력',
            item: '시간',
        },
        nav: {
            coinage: '주화',
            popularization: '홍보',
            exchange: '교환',
            asset: '자산',
            wallet: '지갑',
            logOut: '로그아웃',
        },
        invite: {
            personalPerformance: '개인 이익',
            teamPerformance: '팀 업적',
            myInviterAddress: '나의 초청인 주소',
            myInvitationLink: '나의 초청 링크',
            myDirectInvitationData: '나의 초청 데이터',
            myPower: '나의 초청 산력',
            computingPower: '매일 석방 산력',
            releasePower: '나의 석방 총 산력',
            binding: '귀속',
            address: '주소',
            power: '산력',
            invitingPower: '초청 산력',
            myComputingPower: '나의 현재 총 산력',
            teamRevenueL: '팀 입금',
            teamLevel: '팀 VIP레벨',
            promptCode: '올바른 초대 코드를 입력하십시오.',

            staticProfit: '정적 이익',
            helpProfit: '수익 지원',
            directPushProfit: '직추 이익',
            manageProfit: '이익 관리',
            levelProfit: '동급 이익',
            teamProfit: '팀 이익',
            inviterAddress: '초대 주소',
            item: '시간',
            direct_push_total: '내 직추 총 입금',
            team_power_total: '팀 총산력',
            team_total_usdt: '팀 총 입금',
            team_total_bsdt: '팀 총 입금',
            team_level_count: '팀 수준',
            region: '큰 구역',
            smallArea: '아파트 단지',
            record: '기록',
        },
        exchange: {
            notYetOpen: '아직 개방하지 않음',
            contractAddress: '계약 주소',
            exchange: '교환',
            exchangeRecords: '환전 기록',
            exchangeSuccess: '교환 성공',
            desc: '유형',
            number: '수량',
            tiem: '시간',
            copy: '복사',
        },
        conis: {
            withdraw: '인출',
            recharge: '충전',
            record: '기록',
            platform_address: '수금 주소',
            type_desc: '유목',
            amount: '금액',
            free: '수수료',
            state_desc: '상태',
            within: '',
            // within: '15min 이내 입금',
            clickRecharge: '원클릭 충전',


            staticOutput: '24시간 정태 산출 명세',
            dynamicOutput: '24시간 동태 산출 명세',
            assistanceReward: '도움 장려 명세',
        }
    },
    nav: {
        title: 'Aave V2',
        user_name: 'Aave V2',
        home: 'Home',
        account: 'Account',
        activity: 'Activity',
        history: 'Transaction History',
        referral: 'Referral',
        help: 'Help Center',
        whitePaper: 'White Paper',
        language: 'Select Language',
    },
    index: {
        verifier_map: 'ETH verifier map',
        total_ore: 'Total output (ETH)',
        today_eth: 'Today\'s profit (ETH)',
        effective_node: 'Valid node',
        user_revenue: 'User revenue',
        peoples: 'participants',
        receipt_voucher: 'Receipt voucher',
        join_now: 'Join the node immediately and start mining',
        receive: 'Receive',
        view_details: 'View Details',
        participate_activities: 'Participate in activities',
        earn_eth: 'earn ETH',
        user_output: 'User output',
        address: 'Address',
        number: 'Quantity',
        cooperative_partner: 'Partner',
        confirm_view: 'Confirm View',
        notice: 'Notice',
        close: 'Close',
    },
    account: {
        account_number: 'Account',
        wallet_balance: 'Wallet balance (USDT)',
        exchangeable: 'Exchangeable  (ETH)',
        exchange: 'Exchange',
        withdrawal: 'Withdrawal',
        on_chain_balance: 'On-chain balance (USDT)',
        total_output: 'Total output (ETH)',
        mining_reward: 'Mining reward',
        contract_award: 'Contract award',
        quantity: 'Quantity',
        time: 'Time',
        loading: 'Loading...',
        no_more: 'No more',
        load_more: 'Load More',
        event_rewards: 'Event Rewards',
        view_details: 'View details',
    },
    withdraw: {
        exchange: 'Exchange',
        all: 'All',
        eth_to_usdt: 'Exchange ETH for USDT',
        enter_quantity: 'Please enter the quantity',
        exchange_success: 'Redemption succeeded',
    },
    exchange: {
        withdrawal: 'Withdrawal',
        all: 'All',
        mini_amount: 'The minimum withdrawal amount is',
        payment_date: 'It will be sent to the wallet address within 24 hours',
        withdrawal_but: 'Withdrawal',
        withdrawal_success: 'Withdrawal succeeded',
    },
    activity: {
        title: 'Activity',
        no_data: 'No data temporarily',
        threshold: 'USDT threshold:',
        reward_amount: 'Reward amount:',
        distribution_period: 'Distribution period (days):',
        term_validity: 'Validity:',
        participate_activities: 'Participate in activities',
        absent: 'Absent',
        progress: 'Ongoing',
        cancel: 'Cancel',
        settled: 'Settled',
        standard: 'Standard',
        view_details: 'View Details',
        confirm: 'Unidentified',
        success: 'Successful participation',
        cancel_but: 'Cancel',
        accept_but: 'Accept',
        apply_rewards: 'Apply for rewards',
        apply_info: ' ETH will send it to your account on the third day after the successful pledge',
        received_term_validity: 'Effective time of receipt',
        day: 'Day',
    },
    history: {
        title: 'Transaction history',
        exchange_details: 'Exchange details',
        exchange: 'Exchange',
        eth_num: 'ETH quantity',
        usdt_num: 'USDT quantity',
        free_amount: 'Service charge (USDT)',
        withdrawal_details: 'Withdrawal details',
        success: 'Success',
        under_review: 'Being approved',
        fail: 'Failed',
        amount: 'Quantity：',
        apply_amount: 'Actual receipt:',
        free: 'Service charge: ',
        mining: 'Mining ',
        time: 'Time ',
        mining_amount: 'Quantity ',
        activity_reward: 'Activity reward ',
        pledge_reward: 'Pledge reward ',
        no_data: 'No data temporarily ',
        received: 'Received ',
    },
    referral: {
        invite_friends: 'Invite friends',
        earn_commission: 'Earn commission',
        send_invitation_link: 'Send your invitation link, friends will join the node through your link',
        invitation_code: 'My invitation code',
        share_link: 'My sharing link',
        today_commission: 'Today\'s income (ETH)',
        total_commission: 'Total commission (ETH)',
        commission_withdrawal_limit: 'Transferable out (ETH)',
        peoples_all: 'Number of team members',
        peoples_one: 'Direct initiator',
        commission_transfer: 'Commission transfer',
        transfer_account: 'Transfer commission income into account wallet',
        transfer_all: 'Transfer all',
        transfer: 'Transfer',
        L1: 'Level 1',
        L2: 'Level 2',
        L3: 'Level 3',
        transfer_success: 'Transfer succeeded',
        enter_quantity: 'Please enter the quantity',
        overstep_quantity: 'Exceeding transferable commission',
        mini_quantity: 'Minimum transfer amount al least',
        copy_succee: 'Copy succeeded',
        view_rules: 'View rules',
        team_rules: 'Team commission rules',
        rules1: '1. When you invite more than 2 friends to participate in mining, and the USDT amount of each person participating in mining is between USDT500.00-3000.00, each person invited can get 0.1ETH.',
        rules2: '2. When you invite more than 5 friends to participate in mining, and the USDT amount of each person participating in mining is between USDT3000.00-10000.00, each person invited can get 0.2ETH.',
        rules3: '3. When you invite more than 8 friends to participate in mining, and the USDT amount of each person participating in mining is between USDT10000.00-50000.00, each person invited can get 0.3ETH.',
        rules4: '4. When you invite more than 10 friends to participate in mining, and the USDT amount of each person participating in mining is between USDT50000.00-200000.00, each person invited can get 0.5ETH.',
        rules5: 'You can receive the invitation connection in the recommended connection or the share button in the side menu. Please contact customer service for specific activity rules and rewards!',
    },
    commission: {
        title: 'Commission details',
        all: 'All',
        level1: 'Level 1',
        level2: 'Level 2',
        level3: 'Level 3',
        no_data: 'No data temporarily',
    },
    help: {
        title: 'Help Center',
        no_data: 'No data temporarily ',
    },
    language: {
        title: 'Select language',
        determine: 'OK ',
    },
    remind: {
        tips: 'Tips',
        text1: 'In the wallet, please visit',
        text2: 'You are currently visiting a decentralized website, please open it in the wallet application',
        text3: 'Copy URL',
        text4: 'Copy succeeded',
    },
    details: {
        title: 'Participated in liquidity mining',
        rule: 'Rule',
        reward_history: 'Reward History',
        explain: 'Explain',
        explain_data: 'After obtaining the node voucher, it becomes a liquidity provider, and the USDT stored in the wallet will automatically provide liquidity to obtain ETH rewards, which are generated every 6 hours',
        total_rewards: 'Total rewards (ETH)',
        today_eth: 'Today\'s reward (ETH)',
        received: 'Received',
        rule_data: 'Aave V2 The mine pool will provide computing capacity based on the number of Tether USDTs in the wallet',
    },
    activity_details: {
        title: 'You will receive up to',
        reward: 'Reward',
        end: 'End',
        standard: 'Standard',
        mortgaged: 'Mortgaged',
        pledge_reward: 'Pledge reward',
        not_up: 'Your mortgage amount is not up to standard.',
        up_more: 'Recharge more',
        get_rewards: 'Get rewards.',
        received: 'Received',
        have_hand: 'have in hand - Received',
        not_claimed: 'Not claimed',
        apply_participation: 'Apply for participation',
        end_activity: 'End of activity.',
        day: 'day',
        hour: 'hour',
        min: 'min',
        seconds: 'seconds',
        Collected: 'Collected',
    }

}
