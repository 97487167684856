// zh_CN 简体中文
module.exports = {
    "bring2": {
        "stayTuned": "敬请期待",
        "noMore": "没有更多了",
        "loading": "加载中",
        "gpt": "贝灵GPT",
        "search_time_arr": {
            "bz": "近7天",
            "by": "近1个月",
            "bj": "近3个月",
            "jyn": "近一年",
        },
        "allType":"全部类型",
        "nav": {
            "walletAddress": "当前钱包地址",
            "home": "首页",
            "coin": "AI算力",
            "community": "社区",
            "property": "资产",
            "website": "官网",
            "destroyMining": "销毁挖矿",
            "power": "我的算力",
            "exchange": "闪兑",
            "invite": "邀请",
            "knowledge": "知识库",
            "whitePaper": "白皮书",
            "signedIn": "已签到",
            "signIn": "签到",
            "received": "已领取",
            "signInText": "每次签到可获得1BSDT，可用于抵扣购买算力的支付。持续100天，即满100BSDT可免费提出",
            "plan": "贝灵养成计划",
            "prompt": "完成问答即可签到完成",
            "correctAnswer": "回答正确",
            "wrongAnswer": "回答错误",
            "toLearn": "去贝灵知识库学习",
            "tomorrow": "期待明日再见",
            "obtain": "获得",
            "select": "选择",
        },
        "home": {
            "aiPower": "贝灵AI算力",
            "tripleExpansion": "三倍膨胀财富盛宴",
            "destroyMining": "销毁挖矿",
            "tripleMining": "三重销毁财富增值",
            "aiDialogue": "AI对话 一切皆有答案",
            "digitalHuman": "数字人",
            "website": "官网",
            "view_details": "查看详情",
        },
        "algorithm": {
            "totalInvestment": "总投入",
            "totalPower": "总算力",
            "totallIncome": "总收入",
            "joinNow": "立即参与",
            "joinNowText": "欢迎加入BURNING计算能力模型。您需要至少投资100 BSDT/USDT才能参与",
            "join": "加入",
            "welcomeJoin": "欢迎加入BURING!",
            "enter": "请输入您要转入的",
            "availableBalance": "可用余额",
            "getPower": "恭喜获得算力",
            "determine": "确定",
            "balance": "余额不足",
            "pleaseRecharge": "请充值余额后再购买算力",
            "toRecharge": "去充值",
            "participationRecord": "参与记录",
            "coin": "币种",
            "type": "类型",
            "purchasingPower": "购买算力",
            "power": "算力",
            "date": "日期",
        },
        "mining": {
            "totalInvestment": "总投入",
            "initialLimit": "参与首发额度",
            "totalRevenue": "总收益",
            "welcomeText": "欢迎加入销毁挖矿",
            "pleaseNum": "请输入您要转入的数量",
            "destroyCurrency": "销毁币种",
            "balance": "余额",
            "fillQuantity": "填写数量",
            "participateMining": "参与挖矿",
            "successfully": "销毁挖矿成功",
            "participationRecord": "参与记录",
            "inProgress": "进行中",
            "completed": "已完成",
            "type": "类型",
            "destroyMining": "销毁挖矿",
            "date": "日期",
            "detailedRecords": "详细记录",
            "getPower": "预计获得算力",
            "tips": "提示",
            "determine": "确定",
            "insufficient": "余额不足",
        },
        "power": {
            "participationRecord": "参与记录",
            "staticOutput": "静态产出",
            "dynamicAcceleration": "动态加速",
            "detailedRecords": "详细记录",
            "invitee": "被邀请人",
            "stable_coin": "获得算力",
            "created_at": "获取时间",
            "total_amount": "总算力",
            "total_power": "剩余算力",
        },
        "team":{
            "community":"社区",
            "team_level":"我的社区等级",
            "my_rectangle":"我的业绩",
            "team_rectangle":"社区业绩",
            "my_power":"我的总算力",
            "community_power":"社区总算力",
            "direct_push_count":"我的直推",
            "performance":"按业绩",
            "byTime":"按时间",
            "invite_count":"邀请人数",
            "total_count":"总业绩",
            "created_at":"加入时间",
        },
        "property":{
            "address":"当前钱包地址",
            "total":"总资产",
            "myTotal":"我的资产",
            "recharge":"充币",
            "propose":"提出",
            "transferAccounts":"转账",
            "outDetails":"转出明细",
            "balance":"可用余额",
            "outQuantity":"转出数量",
            "walletAdd":"提出地址",
            "commission":"手续费",
            "actualReceipt":"实际到账",
            "proposeConfirmation":"提出确认",
            "coin":"提出币种",
            "confirmProposal":"确定提出",
            "proposeRecords":"提出记录",
            "homepage":"返回首页",
            "viewRecords":"查看记录",
            "transferDetails":"转入明细",
            "transferredQuantity":"转入数量",
            "toAddress":"转入地址",
            "filling":"充入",
            "chargeRecord":"充入记录",
        },
        "convert":{
            "exchangeCurrency":"兑出币种",
            "balance":"余额",
            "proposedAmount":"填写提出金额",
            "currencyConversion":"兑入币种",
            "commission":"手续费",
            "feeRatio":"手续费比例",
            "exchange":"兑换",
            "exchangeRecords":"兑换记录",
            "completed":"已完成",
            "date":"时间",
        },
        "invite":{
            "myInviteUrl":"我的邀请链接",
            "myInvite":"我的邀请人",
            "generateImages":"生成图片",
            "copyLink":"复制链接",
        },
        "knowledge":{
            "welcomeTo":"欢迎来到",
            "text2":"贝灵知识库",
            "text3":"知识库帮助您完成签到获得BSDT",
        },
        "gptPage":{
            "welcomeTo":"欢迎来到ChaoAI，有任何问题都可以找到我聊聊哦~",
            "pleaseQuestion":"请输入您的问题",
        }
    },


    bring: {
        index: {
            start: '启动'
        },
        loadMore: '加载更多',
        success: '操作成功',
        error: '操作失败',
        promptNum: '请输入正确的数量',
        hint: '合约地址，仅用展示，切勿充值',
        insufficientBalance: '旷工费或余额不足',
        home: {
            myTotalDeposit: '我的总入金',
            myComputingPowerValue: '我的算力值',
            usdtRevenueValue: 'USDT 收益值',
            bsdRevenueValue: 'BSDT 收益值',
            invest: '投入',
            tokenWithdrawal: '提取代币',
            available: '可领取',
            withdrawal: '提取',
            resumption: '复投',

            detail: '明细',
            quantity: '数量',
            coin: '币种',
            power: '算力',
            item: '时间',
        },
        nav: {
            coinage: '铸币',
            popularization: '推广',
            exchange: '兑换',
            asset: '资产',
            wallet: '钱包',
            logOut: '退出',
        },
        invite: {
            personalPerformance: '个人收益',
            teamPerformance: '团队业绩',
            myInviterAddress: '我的邀请人地址',
            myInvitationLink: '我的邀请链接',
            myDirectInvitationData: '我的直邀数据',
            myPower: '我的邀请算力',
            computingPower: '每日释放算力',
            releasePower: '我的释放总算力',
            binding: '绑定',
            address: '地址',
            power: '算力',
            invitingPower: '邀请算力',
            myComputingPower: '我的当前总算力',
            teamRevenueL: '团队入金',
            teamLevel: '团队VIP等级',
            promptCode: '请输入正确邀请码',

            staticProfit: '静态收益',
            helpProfit: '帮扶收益',
            directPushProfit: '直推收益',
            manageProfit: '管理收益',
            levelProfit: '平级收益',
            teamProfit: '团队收益',
            inviterAddress: '邀请地址',
            item: '时间',
            direct_push_total: '我的直推总入金',
            team_power_total: '团队总算力',
            team_total_usdt: '团队总入金',
            team_total_bsdt: '团队总入金',
            team_level_count: '团队平级',
            region: '大区',
            smallArea: '小区',
            record: '记录',
        },
        exchange: {
            notYetOpen: '暂未开放',
            contractAddress: '合约地址',
            exchange: '兑换',
            exchangeRecords: '兑换记录',
            exchangeSuccess: '兑换成功',
            desc: '类型',
            number: '数量',
            tiem: '时间',
            copy: '复制',

        },
        conis: {
            withdraw: '提现',
            recharge: '充值',
            record: '记录',
            platform_address: '充值地址',
            type_desc: '类目',
            amount: '金额',
            free: '手续费',
            state_desc: '状态',
            within: '',
            // within: '15min内到账',
            clickRecharge: '一键充值',

            staticOutput: '24小时静态产出明细',
            dynamicOutput: '24小时动态产出明细',
            assistanceReward: '帮扶奖励明细',
        }
    },
    nav: {
        title: 'Aave V2',
        user_name: 'Aave V2',
        home: '首页',
        account: '账号',
        activity: '活动',
        history: '交易历史',
        referral: '邀请好友',
        help: '帮助中心',
        whitePaper: '白皮书',
        language: '选择语言',


    },
    index: {
        verifier_map: 'ETH 验证者地图',
        total_ore: '总产出 (ETH)',
        today_eth: '今日盈利 (ETH)',
        effective_node: '有效节点',
        user_revenue: '用户收入',
        peoples: '参与者',
        receipt_voucher: '接收凭证',
        join_now: '立即加入节点 开始挖矿',
        receive: '接收',
        view_details: '查看详细信息',
        participate_activities: '参加活动',
        earn_eth: '赚 ETH',
        user_output: '用户输出',
        address: '地址',
        number: '数量',
        cooperative_partner: '合作伙伴',
        confirm_view: '确认查看',
        notice: '公告',
        close: '关闭',
    },
    account: {
        account_number: '账号',
        wallet_balance: '钱包余额 (USDT)',
        exchangeable: '可交换的 (ETH)',
        exchange: '兑换',
        withdrawal: '提现',
        on_chain_balance: '链上余额 (USDT)',
        total_output: '总产出 (ETH)',
        mining_reward: '挖矿奖励',
        contract_award: '合约奖励',
        quantity: '数量',
        time: '时间',
        loading: '加载中...',
        no_more: '没有更多了',
        load_more: '加载更多',
        event_rewards: '活动奖励',
        view_details: '查看详细信息',
    },
    withdraw: {
        exchange: '兑换',
        all: '全部',
        eth_to_usdt: '将ETH兑换为USDT',
        enter_quantity: '请输入数量',
        exchange_success: '兑换成功',
    },
    exchange: {
        withdrawal: '提现',
        all: '全部',
        mini_amount: ' 最低提款金额为',
        payment_date: ' 将在24小时内发送到钱包地址',
        withdrawal_but: '提现',
        withdrawal_success: '提现成功',

        exchangeRecords: '兑换记录',
        desc: '类型',
        number: '数量',
        tiem: '时间',
    },
    activity: {
        title: '活动',
        no_data: '暂无数据',
        threshold: 'USDT门槛:',
        reward_amount: '奖励数量:',
        distribution_period: '派发周期(天):',
        term_validity: '有效期:',
        participate_activities: '参加活动',
        absent: '未参加',
        progress: '进行中',
        cancel: '已取消',
        settled: '已结算',
        standard: '标准',
        view_details: '查看详情',
        confirm: '待确认',
        success: '参加成功',
        cancel_but: '取消',
        accept_but: '接受',
        apply_rewards: '申请奖励',
        apply_info: ' ETH将在质押成功后的第三天发送到您的账号',
        received_term_validity: '收到有效时间',
        day: '天',
    },
    history: {
        title: '交易历史',
        exchange_details: '兑换明细',
        exchange: '兑换',
        eth_num: 'ETH数量',
        usdt_num: 'USDT数量',
        free_amount: '手续费(USDT)',
        withdrawal_details: '提现明细',
        success: '成功',
        under_review: '审核中',
        fail: '失败',
        amount: '数量：',
        apply_amount: '实际到账：',
        free: '手续费： ',
        mining: '挖矿 ',
        time: '时间 ',
        mining_amount: '数量 ',
        activity_reward: '活动奖励 ',
        pledge_reward: '质押奖励 ',
        no_data: '暂无数据 ',
        received: '已领取 ',

    },
    referral: {
        invite_friends: '邀请好友',
        earn_commission: '赚佣金',
        send_invitation_link: '发送您的邀请链接，好友将通过您的链接加入节点',
        invitation_code: '我的邀请码',
        share_link: '我的分享链接',
        today_commission: '今日收入 (ETH)',
        total_commission: '总佣金 (ETH)',
        commission_withdrawal_limit: '可转出 (ETH)',
        peoples_all: '团队人数',
        peoples_one: '直接发起人',
        commission_transfer: '佣金转移',
        transfer_account: '将佣金收入转入账户钱包',
        transfer_all: '全部转移',
        transfer: '转移',
        L1: '等级一',
        L2: '等级二',
        L3: '等级三',
        transfer_success: '转移成功',
        enter_quantity: '请输入数量',
        overstep_quantity: '超出可转移佣金',
        mini_quantity: '最低转账金额至少为',
        copy_succee: '复制成功',
        view_rules: '查看规则',
        team_rules: '团队佣金规则',
        rules1: '1. 当你邀请2个以上的朋友参与挖矿，且每个人参与挖矿的USDT金额在USDT500.00-3000.00之间时，则每个邀请1人可获得0.1ETH。',
        rules2: '2. 当你邀请超过5位好友参与挖矿，且每个人参与挖矿的USDT金额在USDT3000.00-10000.00之间时，则每个邀请1人可获得0.2ETH。',
        rules3: '3.当你邀请超过8位好友参与挖矿，且每个人参与挖矿的USDT金额在USDT10000.00-50000.00之间时，则每个邀请1人可获得0.3ETH。',
        rules4: '4、当你邀请超过10位好友参与挖矿，且每个人参与挖矿的USDT金额在USDT50000.00-200000.00之间时，则每个邀请1人可获得0.5ETH。',
        rules5: '您可以在推荐连接或侧菜单中的分享按钮中收到邀请连接。具体活动规则及奖励请联系客服！',
    },
    commission: {
        title: '佣金明细',
        all: '所有',
        level1: '等级一',
        level2: '等级二',
        level3: '等级三',
        no_data: '暂无数据 ',

    },
    help: {
        title: '帮助中心',
        no_data: '暂无数据 ',
    },
    language: {
        title: '选择语言',
        determine: '确定 ',
    },
    remind: {
        tips: '提示',
        text1: '在钱包请访问',
        text2: '您目前正在访问一个去中心化网站，请在钱包应用程序中打开它',
        text3: '复制URL',
        text4: '复制成功',
    },
    details: {
        title: '已参与流动性挖矿',
        rule: '规则',
        reward_history: '奖励历史',
        explain: '说明',
        explain_data: '获取节点凭证后成为流动性提供者，钱包中存储USDT将自动提供流动性获取ETH奖励，奖励每6小时产生一次',
        total_rewards: '总奖励 (ETH)',
        today_eth: '今日奖励 (ETH)',
        received: '已收到',
        rule_data: 'Aave V2矿池将根据钱包中Tether USDT的数量提供计算能力',
    },
    activity_details: {
        title: '您将接收高达',
        reward: '奖励',
        end: '结束',
        standard: '标准',
        mortgaged: '已抵押',
        pledge_reward: '质押奖励',
        not_up: '您的按揭金额未达标。',
        up_more: '充值更多',
        get_rewards: '获得奖励。',
        received: '已收到',
        have_hand: '进行中 . 已领取',
        not_claimed: '未领取',
        apply_participation: '申请参加',
        end_activity: '活动结束。',
        day: '天',
        hour: '小时',
        min: '分钟',
        seconds: '秒',
        collected: '已收集',
    }

}
